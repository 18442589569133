import React from 'react';
import PropTypes from "prop-types";

const SvgIcon = ({ className: svgClassName, useHref }) => (
  <svg className={`svg-icon ${svgClassName}`} aria-hidden="true">
    <use xlinkHref={"#" + useHref} />
  </svg>
);

const ExternalLinkIcon = ({className: svgClassName}) => <SvgIcon className={`icon-external ${svgClassName}`} useHref='icon-external-link' />

SvgIcon.propTypes = {
  svgClassName: PropTypes.string,
  useHref: PropTypes.string.isRequired
};

ExternalLinkIcon.propTypes = {
  svgClassName: PropTypes.string
};

export {
  SvgIcon,
  ExternalLinkIcon
};
