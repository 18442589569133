import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import SvgIcons from '../components/SvgIcons';
import ButtonBackToTop from './ButtonBackToTop';
import './all.scss';
import useSiteMetadata from './SiteMetadata';
import { withPrefix } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Maintenance from './Maintenance';
import SkipToContent from './SkipToContent';

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
const maintenance = process.env.GATSBY_MAINTENANCE || false;

const TemplateWrapper = ({ children, pageType }) => {
  const { title, description, siteUrl } = useSiteMetadata();

  return maintenance ? (
    Maintenance()
  ) : (
    <div className="main-container">
      {activeEnv === 'staging' && (
        <div
          style={{
            textAlign: 'center',
            fontSize: '15pt',
            backgroundColor: 'yellow',
          }}
        >
          This is a {activeEnv} environment. It's available only inside the
          WTT network. If you are looking for a live version, please
          visit:{' '}
          <a href="https://wttech.blog/">https://wttech.blog</a>.
        </div>
      )}
      <Helmet>
        <html lang="en" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#410194ff"
        />

        <meta name="theme-color" content="#fff" />
      </Helmet>
      <GatsbySeo
        title={title}
        titleTemplate="%s"
        description={description}
        openGraph={{
          title: `${title}`,
          description: `${description}`,
        }}
      />
      <header className="header">
        <SkipToContent
          hideSkipToFooter={pageType === "PageWithFilters"} />
        <Navbar />
      </header>
      <main id="main-content" className={pageType ? pageType : ''}>{children}</main>
      <ButtonBackToTop />
      <Footer />
      <SvgIcons />
    </div>
  );
};

export default TemplateWrapper;
