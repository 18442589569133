import React from 'react';
import { Link } from 'gatsby';
import logo from '../../img/logo-snowflake-vml.svg';

const Logo = () => {
  return (
    <div className="navbar-brand">
      <Link to="/" className="navbar-logo" aria-label="Wunderman Thompson Technology home">
        <img src={logo} alt="VML logo" />
      </Link>
    </div>
  );
};

export default Logo;