import React from 'react';

const HamburgerBtn = ({ classes, activeClass, ariaLabel, ariaExpanded }) => {
  return (
    <button
      className={`${classes} ${activeClass}`}
      data-target="navMenu"
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
    >
      <span />
      <span />
      <span />
    </button>
  );
};

export default HamburgerBtn;
