import React, { useState, useEffect } from 'react';
import Logo from './Navbar/Logo';
import NavBarItems from './Navbar/NavbarItems';
import HamburgerBtn from './Navbar/HamburgerBtn';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import FocusTrap from 'focus-trap-react';

const Navbar = () => {
  const breakpoints = useBreakpoint();
  const [open, setOpen] = useState(false);
  const toggleHamburger = () => {
    setOpen(!open);
  };

  useEffect(() => {
      const overflowValue = open && breakpoints.phoneAndTabletExtended ? 'hidden' : '';   
      document.body.style.overflow = overflowValue;
  }, [open, breakpoints.phoneAndTabletExtended]);

  const toggleNavigation = e => {
    if (breakpoints.phoneAndTabletExtended && !e.target.classList.contains('navbar-items')) {
      toggleHamburger();
    }
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main-navigation">
      <div className="container navbar-wrapper">
        <Logo />
        <div
          id="navMenu"
          className={`navbar-menu ${open ? 'is-active' : ' '}`}
          onClick={e => toggleNavigation(e)}
        >
          <Link
            className="navbar-menu-mobile-search-icon hover-jump-small-child"
            to="/search"
          >
            <svg className="square-xs svg-icon" aria-hidden="true">
              <use href="#icon-search" />
            </svg>
            <span className="sr-only">Search</span>
          </Link>
          {breakpoints.phoneAndTabletExtended ? (
            <FocusTrap
              active={ open }
              >
              <div>
                  <HamburgerBtn
                    classes={'navbar-burger burger'}
                    activeClass={open ? 'is-active' : ''}
                    ariaLabel={open ? 'close navigation' : 'open navigation'}
                    ariaExpanded={open}
                    onClick={() => toggleHamburger()}
                  />
                <NavBarItems />
              </div>
            </FocusTrap>
            ) : <NavBarItems /> }
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
