import React from 'react';
import { Link } from 'gatsby';
import { SvgIcon } from '../SvgIcon';

const NavBarItems = () => {
  const links = [
    {
      link: '/blog/',
      specialClass: 'pseudo-bg-color-blog',
      text: 'Blog',
    },
    {
      link: '/open-source/',
      specialClass: 'pseudo-bg-color-open-source ',
      text: 'Open Source',
    },
    {
      link: '/events/',
      specialClass: 'pseudo-bg-color-events',
      text: 'Events',
    },
    {
      link: '/about/',
      specialClass: 'pseudo-bg-color-default',
      text: 'About',
    },
  ];

  return (
    <div className="navbar-items">
      <div className="navbar-start">
        {links.map(item => (
          <Link
            className={`navbar-item relative hover-border-bottom-swipe-in ${item.specialClass}`}
            to={item.link}
            key={item.link}
          >
            {item.text}
          </Link>
        ))}
      </div>
      <div className="navbar-end">
        <Link
            className="navbar-menu-search navbar-item hover-jump-small-child"
            to="/search"
          >
          <svg className="square-xlarge svg-icon" aria-hidden="true">
            <use href="#icon-search" />
          </svg>
          <span className="sr-only">Search</span>
        </Link>
        <a
          className="navbar-item hover-jump-small-child"
          href="https://github.com/wttech"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SvgIcon className="square-xlarge" useHref="icon-github" />
          <span className="sr-only">
            Cognifide Github, opens in a new window
          </span>
        </a>
      </div>
    </div>
  );
};

export default NavBarItems;
