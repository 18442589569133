import React from 'react';
import {Helmet} from "react-helmet";
import {withPrefix} from "gatsby";
import {GatsbySeo} from "gatsby-plugin-next-seo";

const Maintenance = () => (
    <div className="main container-narrow error-page">
      <Helmet>
        <html lang="en" />
        <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon-32x32.png`}
            sizes="32x32"
        />
        <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon-16x16.png`}
            sizes="16x16"
        />

        <link
            rel="mask-icon"
            href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
            color="#ff4400"
        />

        <meta name="theme-color" content="#fff" />
      </Helmet>
      <GatsbySeo
          title='Wunderman Thompson Technology Blog'
          titleTemplate = '%s'
          description='Wunderman Thompson Technology Blog - maintenance page'
      />
      <section>
        <div className="container content">
          <div className="content-wrapper">
            <div className="text-container">
              <h1>Wunderman Thompson Technology Blog</h1>
              <p>We are down for maintenance. Be right back!</p>
            </div>
          </div>
        </div>
      </section>
    </div>
);

export default Maintenance;