import React from 'react';
import { Link } from 'gatsby';
import { SvgIcon } from './SvgIcon';
import Logo from './Navbar/Logo';

const Footer = class extends React.Component {
  render() {
    return (
      <footer
        id="footer-content"
        className="footer"
      >
        <h2 className="sr-only">Website footer</h2>
        <div className="footer-container-wrapper">
          <div className="footer-wrapper grid-container">
            <div className="footer-wrapper-logo col-xs-12 col-md-4 col-lg-3">
              <Logo />  
            </div>
            <h3 className="sr-only">Wunderman Thompson Tech Hub links</h3>
            <div className="footer-wrapper-links col-xs-6 off-md-1 col-md-3 col-lg-2">
              <section>
                <ul className="menu-list">
                  <li>
                      <Link className="navbar-item hover-underline" to="/blog/">
                        Posts
                      </Link>
                    </li>
                  <li>
                    <Link className="navbar-item hover-underline" to="/events/">
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="navbar-item hover-underline"
                      to="/open-source/"
                    >
                      Open source
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="navbar-item hover-underline"
                      to="/authors/"
                    >
                      Authors
                    </Link>
                  </li>
                </ul>
              </section>
            </div>
            <div className="footer-wrapper-links col-xs-6 off-md-1 col-md-3 col-lg-2">
              <section className="menu">
                <ul className="menu-list">
                  <li>
                    <Link className="navbar-item hover-underline" to="/about/">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item hover-underline" to="/terms-of-use/">
                      Terms of use
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item hover-underline" to="/privacy-policy/">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item hover-underline" to="/cookie-notice/">
                      Cookie Notice
                    </Link>
                  </li>

                </ul>
              </section>
            </div>
            <div className="footer-wrapper-socialLinks col-xs-12 col-md-12 col-lg-3">
              <h3 className="sr-only">Cognifide on social media</h3>
              <a
                title="Facebook"
                href="https://facebook.com/WunThompsonTECHPL"
                className="hover-jump-small-child"
              >
                <SvgIcon className="square-medium" useHref="icon-facebook" />
                <span className="sr-only">Facebook</span>
              </a>
              <a
                title="Twitter"
                href="https://twitter.com/WunThompsonTECH"
                className="hover-jump-small-child"
              >
                <SvgIcon className="square-medium" useHref="icon-twitter" />
                <span className="sr-only">Twitter</span>
              </a>
              <a
                title="Twitter"
                href="https://github.com/wttech/"
                className="hover-jump-small-child"
              >
                <SvgIcon className="square-medium" useHref="icon-github" />
                <span className="sr-only">Github</span>
              </a>
              <a
                title="Youtube"
                href="https://www.youtube.com/channel/UCAnULb3fPkpvXorGYJxw-Jg/about"
                className="hover-jump-small-child"
              >
                <SvgIcon className="square-medium" useHref="icon-youtube" />
                <span className="sr-only">Youtube</span>
              </a>
              <a
                title="LinkedIn"
                href="https://www.linkedin.com/company/wunderman-thompson-technology"
                className="hover-jump-small-child"
              >
                <SvgIcon className="square-medium" useHref="icon-linkedin" />
                <span className="sr-only">LinkedIn</span>
              </a>
            </div>
          </div>
          <div className="content footnote">
            <h3 className="sr-only">Legal information</h3>
            <p className="legal">
              © {new Date().getFullYear()} Wunderman Thompson. A WPP Company.
            </p>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
