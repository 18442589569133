import React from 'react';

const SkipToContent = ({hideSkipToFooter}) => {
  return (
    <div className="skip-links-container" >
      <a className="skip-link" href="#main-content">
        Skip Navigation
      </a>
      { !hideSkipToFooter && (
          <a className="skip-link" href="#footer-content">
          Skip to Footer
          </a>
        )
      }
      
    </div>
  );
};

export default SkipToContent;
