import React from 'react';
import { SvgIcon } from './SvgIcon';
import { withPrefix } from 'gatsby';

const ButtonBackToTop = class extends React.Component {
  state = {
    pinToBottom: '',
    windowYOffset: 0,
  };

  scrollToTopWrapper = React.createRef();

  componentDidMount() {
    const cookiesButton = document.querySelector(".accept-cookies-button");
    window.addEventListener('scroll', this.updateState);
    window.addEventListener('resize', this.updateState);
    if (cookiesButton) {
      cookiesButton.addEventListener('click', this.willUpdateState)
    }
  }

  componentWillUnmount() {
    const cookiesButton = document.querySelector(".accept-cookies-button");
    window.removeEventListener('scroll', this.updateState);
    window.removeEventListener('resize', this.updateState);
    if (cookiesButton) {
      cookiesButton.removeEventListener('click', this.willUpdateState)
    }
  }

  willUpdateState = () => {
    setTimeout(this.updateState, 500)
  };

  updateState = () => {
    const buttonHeight = document.querySelector('.scroll-to-top').offsetHeight;
    const setCookieOffset = (cookieNoticeOffset) => {
      if (!this.scrollToTopWrapper.current) {
        return;
      }
      if (this.state.pinToBottom) {
        this.scrollToTopWrapper.current.style.transform = `translate(0, -${buttonHeight}px)`;
      } else {
        this.scrollToTopWrapper.current.style.transform = `translate(-50%, -${cookieNoticeOffset}px)`;
      }
    };
    let mainContainerHeight = document.querySelector('.main-container')
      .clientHeight;
    let footerHeight = document.querySelector('footer').clientHeight;
    let buttonPosition = mainContainerHeight - footerHeight;
    let pageHeight = window.pageYOffset + window.innerHeight;
    const cookieNotice = document.querySelector('.optanon-alert-box-wrapper')
    const cookieNoticeOffset = cookieNotice && cookieNotice.offsetHeight ? cookieNotice.offsetHeight : 0;

    this.setState({
       windowYOffset: `${window.pageYOffset}`,
       pinToBottom: pageHeight > (buttonPosition + cookieNoticeOffset),
    });

    setCookieOffset(cookieNoticeOffset);
  };

  scrollToTop() {
    document.body.tabIndex = '0';
    document.body.focus();
    document.body.tabIndex = '-1';

    window.scrollTo(0, 0);
  }

  render() {
      return (
        <div
          ref={this.scrollToTopWrapper}
          hidden={this.state.windowYOffset < 60}
          className={`scroll-to-top-container ${
            this.state.pinToBottom ? 'pin-to-bottom' : ''
          }`}
        >
          <button
            className="scroll-to-top hover-bounce-child"
            onClick={() => this.scrollToTop()}
            aria-label="Back to top"
            >
            <SvgIcon className="arrow-up-icon" useHref="icon-arrow-up2" />
          </button>
        </div>
      );
  }
};

export default ButtonBackToTop;
